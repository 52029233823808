@import "./variables";
.wrapper {
  padding-bottom: 2rem;
}
.content {
  @media screen and (min-width: 1360px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
.column {
  @media screen and (min-width: 1360px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
}
.coopTitle {
  padding: 1rem 0;
}
.coopParagraph {
  margin-bottom: 2rem;
  @media screen and (min-width: 660px) {
    font-size: 1.1rem;
    width: 70%;
  }
}
.coopImg {
  padding: 2rem 0 2rem;
  @media screen and (min-width: 660px) {
    width: 80%;
    margin-left: auto;
    padding: 2rem 0;
  }
  @media screen and (min-width: 1360px) {
    padding: 0 0 2rem;
    width: 100%;
  }
}
.coopPicture {
  width: 100%;
}
.h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $textDark;
  padding: 1rem 0 2rem;
  @media screen and (min-width: 660px) {
    font-size: 2.8rem;
  }
}
.list {
  padding: 0 0 0 2rem;
  list-style: none;
  @media screen and (min-width: 660px) {
    width: 70%;
  }
  @media screen and (min-width: 1360px) {
    width: 100%;
  }
}
.listItem {
  font-size: 0.9rem;
  padding: 0.5rem 0;
  position: relative;
  color: $textBlack;
  &:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    background-color: $mainRed;
    left: -15px;
    top: 12px;
  }
  @media screen and (min-width: 660px) {
    font-size: 1.1rem;
    padding: 0.7rem 0;
  }
}
