@import "./variables";

.nav {
  display: none;
  position: fixed;
  width: 100vw;
  height: 75px;
  margin: 0 auto;
  background-color: $navBg;
  z-index: 10;
  @media screen and (min-width: 1200px) {
    display: block;
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5rem 0 0;
}
.menu {
  display: flex;
  width: 80%;
  @media screen and (max-width: 900px) {
    display: none;
  }
}
.navList {
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: end;
  list-style: none;
  text-decoration: none;
}
