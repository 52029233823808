@import "./variables";

.wrapper {
  padding: 0 4rem 0 0;
  @media screen and (max-width: 1200px) {
    padding: 0 1rem 0 0;
  }
}
.icon {
  display: block;
  width: 50px;
  height: 50px;
}
.info {
  font-size: 0.8rem;
}
