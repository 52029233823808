@import "./variables";

.nav {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 60px;
  margin: 0 auto;
  background-color: $textDark;
  z-index: 10;
  @media screen and (min-width: 1200px) {
    display: none;
  }
}
.navActive {
  position: fixed;
  width: 100vw;
  height: 60px;
  margin: 0 auto;
  background-color: $textWhite;
  z-index: 10;
  border-bottom: 2px solid $mainRed;
}
.wrapperNav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hamburger {
  padding-right: 1rem;
  cursor: pointer;
}
.hidden {
  position: absolute;
  top: 60px;
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
  transform: translateX(100%);
  width: 100%;
  height: 100vh;
  padding-top: 3rem;
}
.isActive {
  position: absolute;
  top: 60px;
  width: 100%;
  height: 100vh;
  padding-top: 3rem;
  transition: 0.4s;
  background-color: rgb(255, 255, 255);
}
.navList {
  list-style: none;
}
.listItem {
  padding: 0.25rem 0;
}
.logoMobileActive {
  padding: 0.5rem 0.75rem;
}
.menuOpen {
  width: 25px;
  height: 2px;
  top: -10px;
  left: 5px;
  background-color: $textWhite;
  position: relative;

  transform-origin: 0 0;
  transition: 0.4s;
  &:after,
  &:before {
    position: absolute;
    content: "";
    background-color: $textWhite;
    width: 25px;
    height: 2px;
    transition: 0.4s;
  }
  &:after {
    top: 16px;
  }
  &:before {
    top: 8px;
  }
}
.menuClose {
  width: 25px;
  height: 2px;
  top: -2px;
  left: 5px;
  background-color: $textDark;
  position: relative;

  transform: rotate(45deg);
  transform-origin: 0 0;
  transition: 0.4s;

  &:after {
    position: absolute;
    content: "";
    background-color: $textDark;
    transform: rotate(-270deg);
    width: 25px;
    height: 2px;
    transition: 0.4s;
  }
}
