@import "./variables";

.wrapper {
  padding: 3rem 0 0;
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 1200px) {
    padding: 0;
    display: grid;
    grid-template-columns: 3fr 3fr;
    align-items: center;
  }
}

.image {
  background-image: url("../images/Praktyki-mobile.webp");
  background-repeat: no-repeat;
  min-height: 410px;
  margin: 2rem 0 0;
  background-position: 100% 0;
  @media screen and (min-width: 660px) {
    background-image: url("../images//Praktyki-tablet.webp");
    background-size: contain;
    min-height: 80vh;
    margin: 2rem 0 1rem;
  }
  @media screen and (min-width: 1200px) {
    background-image: url("../images//Praktyki.webp");
    background-size: contain;
    min-height: 90vh;
    margin: 2rem 0 1rem;
  }
  @media screen and (min-width: 1360px) {
    height: auto;
  }
}

.info {
  padding: 0 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.25) {
    zoom: 0.75;
  }
  @media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    zoom: 0.6;
    margin-left: 15rem;
  }
  @media screen and (min-width: 660px) {
    max-width: 550px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 800px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 640px;
  }
  @media screen and (min-width: 1360px) {
    margin-left: 6rem;
  }
  @media screen and (min-width: 1560px) {
    margin-left: 16rem;
  }
}
.h4 {
  color: $mainRed;
  font-size: 1.5rem;
  font-weight: 400;
  @media screen and (min-width: 660px) {
    font-size: 1.5rem;
  }
}
.h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $textDark;
  padding: 1rem 0 0;
  @media screen and (min-width: 660px) {
    font-size: 3.1rem;
  }
  @media screen and (min-width: 1360px) {
    padding: 2rem 0;
  }
}
.paragraph {
  line-height: 23px;
  font-size: 0.9rem;
  color: $textBlack;
  padding: 1rem 0;
  @media screen and (min-width: 660px) {
    font-size: 1.1rem;
  }
}

.infoLeft {
  padding: 4rem 10rem 4rem 10rem;
  width: 60%;
}
.picture {
  width: 100%;
}
