@import "./variables";

.wrapper {
  padding: 2rem 0 2rem;
  font-family: "Gilroy-Regular";
  @media screen and (min-width: 1024px) {
    padding: 4rem 0 0;
  }
}
.h3 {
  font-size: 1.25rem;
  color: $mainRed;
}
.benefits {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  @media screen and (min-width: 660px) {
    grid-template-columns: repeat(3, 1fr);
    width: 90vw;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    width: 50vw;
    padding: 4rem 0 0;
    gap: 1rem;
  }
}
.hiperlink {
  cursor: pointer;
  padding: 0 2rem;
  font-family: "Gilroy-Bold";
  & img {
    transform: rotate(180deg);
    padding: 0 0 0 2rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 4rem 0;
  }
}
.span {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: $textBlack;
    bottom: 0;
    left: 0;
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: $textWhite;
    bottom: 0;
    left: -100%;
    transform-origin: 0% 0;
  }
  &:hover:after {
    animation: slide 0.6s;
  }
}
@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(200%);
  }
}
