@import "./variables";

.wrapper {
  padding: 2rem 1rem 4rem;
}
.desktopView {
  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: 3fr 3fr;
    align-items: center;
  }
}
.image {
  background-image: url("../images//Staz-mobile.webp");
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 90vw;
  min-height: 410px;
  margin: 2rem 0 1rem;
  @media screen and (min-width: 660px) {
    background-image: url("../images//Staz-tablet.webp");
    background-size: contain;
    max-width: 90vw;
    min-height: 80vh;
    margin: 2rem 0 1rem;
  }
  @media screen and (min-width: 1200px) {
    background-image: url("../images//Staz.webp");
    background-size: contain;

    min-height: 90vh;
    height: auto;
    margin: 2rem 0 1rem;
  }
  @media screen and (min-width: 1360px) {
    height: auto;
  }
}
.info {
  padding: 2rem 0rem 0 0rem;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.25) {
    zoom: 0.75;
  }
  @media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
    zoom: 0.6;
  }

  @media screen and (min-width: 660px) {
    max-width: 550px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 800px;
  }
  @media screen and (min-width: 1360px) {
    max-width: 600px;
  }
}

.h4 {
  color: $mainRed;
  font-size: 1.25rem;
  font-weight: 400;
  @media screen and (min-width: 660px) {
    font-size: 1.5rem;
  }
}
.h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $textDark;
  padding: 1rem 0;
  @media screen and (min-width: 660px) {
    font-size: 3.1rem;
  }
  @media screen and (min-width: 1360px) {
    padding: 2rem 0;
  }
}
.paragraph {
  line-height: 23px;
  font-size: 0.9rem;
  color: $textBlack;
  padding: 0 0 1rem 0;
  @media screen and (min-width: 660px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1360px) {
    padding: 1rem 0;
  }
}

.hiperlink {
  cursor: pointer;
  font-weight: 700;
  padding: 2rem;
  width: 100%;
  max-width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1360px) {
    padding: 2rem 2rem 2rem 0;
  }
}
.span {
  position: relative;
  padding: 1rem 0 0.5rem;
  overflow: hidden;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: $textBlack;
    bottom: 0;
    left: 0;
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: $textWhite;
    bottom: 0;
    left: -100%;

    transform-origin: 0% 0;
  }
  &:hover:after {
    animation: slide 0.6s;
  }
}

.customLink {
  text-decoration: none;
  color: $textBlack;
}
@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(200%);
  }
}
