@import "./variables";

.contact {
  background-color: $mainRed;
  color: $textWhite;
  font-family: "Gilroy-Medium";
  padding: 0 0.5rem;
}
.h2Contact {
  color: $textWhite;
  font-size: 1.8rem;
  padding: 2rem 0;
  @media screen and (min-width: 660px) {
    font-size: 3.1rem;
  }
}
.contactWrapper {
  padding: 0 0 3rem;
}
.contactParagraph {
  font-size: 1.1rem;
  padding: 0 1rem 1rem 0;
  @media screen and (min-width: 660px) {
    font-size: 1.3rem;
  }
}
.contactName {
  padding: 1rem 0 0;
}
.instagram {
  color: $textWhite;
  font-size: 1.1rem;
  padding: 0 1rem 1rem 0;
  @media screen and (min-width: 660px) {
    font-size: 1.3rem;
  }
}
