@import "./variables";

.wrapper {
  padding: 1rem 0 0;
  display: flex;
  @media screen and (min-width: 660px) {
    padding: 1.5rem 0 0;
  }
}
.details {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.title {
  color: $mainRed;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  font-family: "Gilroy-Bold";
  @media screen and (min-width: 660px) {
    font-size: 1.4rem;
  }
}
.description {
  position: relative;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  @media screen and (min-width: 660px) {
    font-size: 1rem;
    line-height: 23px;
  }
}
