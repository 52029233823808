@import "./variables";

.h2 {
  font-size: 1.8rem;
  font-weight: 700;
  color: $textDark;
  padding: 1rem 0 0rem;
  @media screen and (min-width: 660px) {
    font-size: 3.1rem;
  }
}
.h3 {
  position: relative;
  font-size: 1.4rem;
  padding: 3rem 0 0rem;
  margin: 0 2rem 0 3rem;
  width: 80%;
  &:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    bottom: -1rem;
    background-color: #000000;
  }
  @media screen and (min-width: 660px) {
    font-size: 1.5rem;
    width: 70%;
  }
  @media screen and (min-width: 1360px) {
    padding: 3rem 0;
    width: 70%;
    &:after {
      left: 10px;
      bottom: 0;
    }
  }
}
.wrapper {
  padding: 0 0 3rem;
  @media screen and (min-width: 1360px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.list {
  padding: 0 2rem 0rem;
  list-style: none;
}
.listItem {
  padding: 0.5rem 0;
  position: relative;
  color: $textBlack;
  &:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    background-color: $mainRed;
    left: -15px;
    top: 15px;
  }
}
