@import "./_variables.scss";

.heroImg {
  width: 100vw;
  // background: no-repeat url("../images/Hero-mobile.webp");
  background: no-repeat url("../images/newHeroRes2.jpg");
  background-size: cover;
  background-position: 30% 0%;
  color: $textWhite;
  font-family: "Gilroy-Medium";
  // filter: brightness(80%);

  @media screen and (min-width: 660px) {
    // background: no-repeat url("../images/Hero-tablet.webp");
    background: no-repeat url("../images/newHeroRes2.jpg");
    background-size: cover;
    background-position: 100% 100%;
    width: 100vw;
    // min-height: 850px;
  }
  @media screen and (min-width: 1200px) {
    // background: no-repeat url("../images/Hero.webp");
    background: no-repeat url("../images/newHeroRes2.jpg");
    background-size: cover;
    width: 100vw;
    min-height: 650px;
  }
  @media screen and (min-width: 1360px) {
    // background: no-repeat url("../images/Hero.webp");
    background: no-repeat url("../images/newHeroRes2.jpg");
    background-size: cover;
    // min-height: auto;
    background-position: 50% 50%;
  }
}
.wrapper {
  padding: 7rem 7rem 0 0;
  z-index: 2;
}

.title {
  @media screen and (min-width: 1200px) {
    display: flex;
    gap: 3rem;
    padding: 2rem 0;
  }
}
.h1 {
  font-size: 5rem;
  @media screen and (min-width: 660px) {
    font-size: 8.5rem;
  }
  @media screen and (min-width: 1360px) {
    font-size: 10.5rem;
  }
}
.moreInfo {
  position: relative;
  padding: 4rem 0 3rem 2rem;
  &:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 140px;
    background-color: $mainRed;
    top: 3rem;
    left: 1rem;
    transform-origin: 0 0;
    animation: slide 1.4s infinite;
  }
  @media screen and (min-width: 1200px) {
    padding: 9rem 0 3rem 2rem;
    &:before {
      top: 8rem;
    }
  }
  @media screen and (min-width: 1360px) {
    padding: 15rem 0 3rem 2rem;
    &:before {
      top: 14rem;
    }
  }
}

////ANIMATION

@keyframes slide {
  from {
    transform: scaleY(0);
    transform-origin: 0 0;
    opacity: 0.1;
  }
  10% {
    transform: scaleY(0);
    transform-origin: 0 0;
  }
  40% {
    transform: scaleY(1);
    transform-origin: 0 0;
    opacity: 1;
  }
  60% {
    transform: scaleY(1);
    transform-origin: 0 120%;
    opacity: 1;
  }

  70% {
    transform: scaleY(1);
    transform-origin: 0 100%;
    opacity: 1;
  }
  to {
    transform: scaleY(0);
    transform-origin: 0 100%;
    opacity: 0.4;
  }
}
