@import url("./_variables.scss");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  overflow-x: hidden;
  font-family: "Gilroy-Regular", sans-serif;
}

.container {
  padding: 0 1rem;
  max-width: 1140px;
  margin: 0 auto;
  height: 100%;

  @media screen and (min-width: 450px) {
    padding: 0 calc(1rem + 5vw);
  }
  @media screen and (min-width: 1360px) {
    padding: 0;
    height: auto;
  }
  @media screen and (min-width: 1660px) {
    max-width: 1440px;
    padding: 0;
    height: auto;
  }
}
.link {
  color: #ffffff;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #ff3700;
  }
}
.linkMobile {
  display: block;
  color: #000000;
  font-family: "Gilroy-Medium";
  font-size: 2.4rem;
  text-decoration: none;
  padding: 0rem 1rem;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #ff3700;
  }
}
.link-footer {
  color: #ffffff;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}
.button {
  cursor: pointer;
  z-index: 1;
  position: relative;
  font-family: "Gilroy-Bold";
  font-size: 1rem;

  max-width: 360px;
  width: 100%;
  height: 70px;
  border: 1px solid #ff3700;
  background-color: #ffffff;
  color: #414141;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  overflow: hidden;
  &:after {
    content: url("../images/BtbArrow.png");
  }
  &:hover {
    color: #ffffff;
  }
  &:before {
    z-index: -1;
    content: "";
    width: 300px;
    width: 100%;
    height: 70px;
    position: absolute;
    left: 0;
    background: linear-gradient(90deg, #160744, #6f1a7a, #e54949, #ee8254);
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 0.5s;
  }
  &:hover:before {
    transform: scaleX(1);
    transform-origin: 0% 0;
  }
  &:hover:after {
    filter: invert(100%);
  }
}

//////////////////////////////////////////
/// testbutton
//////////////////////////////////////////
.testbutton {
  position: absolute;
  top: 10%;
  right: 10%;
}
